import React, { useState, useEffect, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useQuery } from 'react-query';
import Header from '../../components/Header';
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Alert } from '@material-ui/lab';
import { useMutation } from 'react-query';
import { get } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { getSelectedStoreAppClipIds } from 'src/utils';
import { useCookies } from 'react-cookie';
import { Editor } from 'src/components/Editor';
import { getItemsApi } from 'src/services/Items';

const useStyles = makeStyles((theme: any) => ({
  input: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    width: 343,
    height: 40,
    display: 'flex',
    alignSelf: 'center',
    marginRight: 8,
  },
  inputEmail: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    width: 343,
    height: 40,
    display: 'flex',
    alignSelf: 'center',
    marginRight: 8,
  },
  errorMessageContainer: {
    width: '100%',
    position: 'absolute',
    bottom: 100,
    [theme.breakpoints.between('lg', 'xl')]: {
      left: 150,
    },
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    zIndex: 5000,
  },
  errorMessage: { maxWidth: 343, flex: 1, fontWeight: 'bold' },
}));

const Items = (props: any) => {
  const classes = useStyles();
  const { stores } = useSelector((state: any) => state.store);

  const [store, selectStore] = useState(stores[0]);
  const [productId, setProductId] = useState('');
  const [productIdentifier, setProductIdentifier] = useState('qr');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Error');
  const [data, setData] = useState();
  const [cookies] = useCookies();

  const onStoreChange = (event: any, value: any) => {
    selectStore(value);
  };

  const fetchItemsData = async () => {
    let params = {
      v1: true,
      store_id: store?.store_id,
      product_identifier: productId,
      // product_identifier_type: productIdentifier,
      customer: 'c276d3b5-fa2c-4b48-a6be-1b00c2f5b67c', //prod
      // customer: 'fee48a1e-c953-48a0-977b-8950e7846c84', //dev
      r_lat: '12.9364428',
      r_long: '77.6261703',
      network_type: '',
      platform: 'Web',
      device_id: '6e38e8eb114e7978669ebdf50ad30337',
      user_language: 'en',
      browser_type: 'android',
      token: cookies.app_token,
      authotization: 'Token 07f13f7a1a8f443c0d24eaec4dc705c9747f6230', //dev
    };

    return getItemsApi.query(params);
  };

  const { isLoading: orderFetchLoading, mutate: onSearchExecuted } =
    useMutation(fetchItemsData, {
      onSuccess: ({ data, status }: any) => {
        console.log({ data });
        let displayData: any = { data, status };
        if (data) {
          setData(displayData);
        } else {
          if (get(data, 'data.data', []).length === 0) {
            setError(true);
            setErrorMessage('No Items found');
          }
        }
      },

      onError: (error: any) => {
        setError(true);
        setErrorMessage('Error fetching orders');
        console.log({ error });
        setData(error);
      },
    });

  return (
    <Box style={{ height: '100vh', width: '100%', overflow: 'scroll' }}>
      <Header
        title="Items"
        subtitle="Showing items of"
        rightAccessory={() => {
          return (
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Autocomplete
                size="small"
                id="free-solo-2-demo"
                options={stores}
                style={{ width: 250, marginRight: 8 }}
                value={store}
                onChange={onStoreChange}
                getOptionLabel={(option: any) => option?.retailer}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Search Store"
                    margin="normal"
                    variant="outlined"
                    InputProps={{ ...params.InputProps, type: 'search' }}
                  />
                )}
              />
              <TextField
                label="Enter Product Identifier type"
                value={productIdentifier}
                onChange={(event: any) => {
                  setProductIdentifier(event.target.value);
                }}
                variant="outlined"
                size={'small'}
                style={{ width: 100, marginTop: 6, marginRight: 8 }}
              />
              <TextField
                label="Enter Product ID"
                value={productId}
                onChange={(event: any) => {
                  setProductId(event.target.value);
                }}
                variant="outlined"
                size={'small'}
                style={{ width: 250, marginTop: 6, marginRight: 8 }}
              />
              <Button
                variant="contained"
                color="primary"
                style={{ marginTop: 6 }}
                onClick={() => onSearchExecuted()}
              >
                Search
              </Button>
            </Box>
          );
        }}
      />
      {orderFetchLoading ? (
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress color="primary" size={30} />
        </Box>
      ) : (
        <Box style={{ height: 'inherit' }}>
          {data ? (
            <Editor mode="json" value={data} height={500} />
          ) : (
            <Box
              style={{
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
                height: '70vh',
              }}
            >
              <Typography>No data available</Typography>
            </Box>
          )}
        </Box>
      )}

      {error && (
        <Box className={classes.errorMessageContainer}>
          <Alert
            className={classes.errorMessage}
            variant="filled"
            severity="error"
          >
            {errorMessage}
          </Alert>
        </Box>
      )}
    </Box>
  );
};

export default Items;
