import axios from 'axios';
import { get } from 'lodash';

interface options {
  url: String;
  method: String;
  params?: Object;
  data?: Object;
}

interface error {
  response: any;
  message: string;
}

// axios.defaults.withCredentials = true;

let createInstance = async (
  options: Object,
  onSuccess: any,
  onError: any,
  v1: boolean | string,
  token: any,
) => {
  const accessToken = token;
  let url;
  switch (v1) {
    case true:
      {
        url = process.env.REACT_APP_NEW_BASE_URL;
      }
      break;
    case false:
      {
        url = process.env.REACT_APP_BASE_URL;
      }
      break;
    case 'items':
      {
        url = process.env.REACT_APP_ITEMS_URL;
      }
      break;
    case 'analytics':
      {
        url = process.env.REACT_APP_ANALYTICS_URL;
      }
      break;

    default: {
      url = process.env.REACT_APP_NEW_BASE_URL;
    }
  }
  const client = axios.create({
    baseURL: url,
    timeout: 800000,
    headers: {
      Accept: 'application/json; charset=utf-8',
      'Content-Type': 'application/json',
      'X-Authorization': 'Token ' + accessToken,
    },
  });
  return client(options).then(onSuccess).catch(onError);
};

const request = (requestOptions: any) => {
  const { params, ...others } = requestOptions;
  const { v1 = false, token = null, ...rest } = params;
  let options = { ...others, params: rest };

  const onSuccess = (response: any) => {
    return response;
  };

  const onError = async (error: error) => {
    //onErrors called different navigatin methods
    let e_401 = [];
    if (error?.response?.status) {
      switch (error.response.status) {
        case 400: {
          console.log(
            'jnsnsmn',
            typeof get(error, 'response.data.error', ''),
            get(error, 'response.data.error', ''),
            error.response,
          );
          throw get(error, 'response.data.error', '');
          //    { error: true, message: get(error, 'response.data.error', '') };
        }
        case 401:
          {
            e_401.push('error');
            if (e_401.indexOf('error') !== -1) {
              //   Alert.alert(
              //     'Session Expired',
              //     'To keep account secured, we logged you out due to inactivity',
              //     [
              //       {
              //         text: 'Relogin',
              //         onPress: async () => {
              //           await cacheService.clear();
              //           preLogin();
              //         },
              //         style: 'cancel',
              //       },
              //     ],
              //     { cancelable: false },
              //   );
            }
          }
          throw 'error';
        case 403: {
          return;
        }
      }
    } else return Promise.reject(error.response || error.message);
  };

  return createInstance(options, onSuccess, onError, v1, token);
};

export default request;
