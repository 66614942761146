import {
  Box,
  CircularProgress,
  ClickAwayListener,
  Grow,
  Input,
  InputAdornment,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ArrowRight from 'src/assets/svgIcons/ArrowRight';
import SearchIcon from 'src/assets/svgIcons/SearchIcon';
import { ordersApi } from 'src/services/orders';
import { getSelectedStoreAppClipIds, goToOrderDetails } from 'src/utils';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme: any) => ({
  input: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    width: 343,
    height: 40,
    display: 'flex',
    alignSelf: 'center',
    marginRight: 8,
  },
  inputEmail: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    width: 343,
    height: 40,
    display: 'flex',
    alignSelf: 'center',
    marginRight: 8,
  },
  errorMessageContainer: {
    width: '100%',
    position: 'absolute',
    bottom: 100,
    [theme.breakpoints.between('lg', 'xl')]: {
      left: 150,
    },
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    zIndex: 5000,
  },
  errorMessage: { maxWidth: 343, flex: 1, fontWeight: 'bold' },
}));

const InputAndPopper = ({
  type = null,
  items = null,
  order_type = null,
}: any) => {
  const classes = useStyles();
  const {
    selected_regions,
    selected_retailers,
    active_stores,
    stores,
    show_demo_stores,
  } = useSelector((state: any) => state.store);
  const intl = useIntl();
  const history = useHistory();
  const [input, setInput] = useState('');
  const [cookies] = useCookies();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [orderData, setOrderData] = React.useState([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Error');
  const [email, setEmail] = useState('');
  useEffect(() => {
    let timer: any = null;
    if (error) {
      timer = setTimeout(() => {
        setError(false);
        setErrorMessage('Error');
      }, 3000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [error]);

  const onChangeText = (e: any) => {
    setAnchorEl(e.currentTarget);
    setInput(e.target.value);
  };

  const onChangeEmail = (e: any) => {
    setAnchorEl(e.currentTarget);
    setEmail(e.target.value);
  };

  const fetchOrdersData = async () => {
    let store_ids = getSelectedStoreAppClipIds(
      stores,
      selected_retailers,
      selected_regions,
      active_stores,
      show_demo_stores,
    );
    let regions = selected_regions.map((region: any) => region.value);
    let store_types = selected_retailers.map((retailer: any) => retailer.value);

    let params = {
      v1: true,
      store_types: store_types.join(),
      app_clip_ids: store_ids.join(),
      regions: regions.join(),
      purchase_type:
        order_type === 'click_collect' ? 'click_and_collect' : 'scan_and_go',
      page: 1,
      token: cookies.app_token,
      show_demo: show_demo_stores,
      email,
      o_id: input,
    };
    return ordersApi.query(params);
  };

  const { isLoading: orderFetchLoading, mutate: fetchOrders } = useMutation(
    fetchOrdersData,
    {
      onSuccess: (data: any) => {
        setInput('');
        if (get(data, 'data.data', []).length > 1) {
          setOpen(true);
          setOrderData(get(data, 'data.data', []));
        } else {
          if (get(data, 'data.data', []).length === 0) {
            setError(true);
            setErrorMessage('No orders found');
          } else {
            goToOrderDetails({
              order_id: get(data, 'data.data[0].order_id', ''),
              type: order_type === 'click_collect' ? 'click_collect' : 'orders',
              from: 'orders',
              history,
            });
          }
        }
      },

      onError: error => {
        setError(true);
        setErrorMessage('Error fetching orders');
      },
    },
  );

  const onPressEnter = (e: any) => {
    if (e.key === 'Enter') {
      if (email.length || input.length) {
        fetchOrders();
      } else {
        setError(true);
        setErrorMessage('Please enter email or order id');
      }
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const goTo = (order_id: any) => {
    handleClose();
    goToOrderDetails({
      order_id,
      type: order_type ? 'click_collect' : 'orders',
      from: 'orders',
      history,
    });
  };

  return (
    <Box
      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
    >
      <Input
        className={classes.inputEmail}
        fullWidth={true}
        name="email"
        onKeyDown={onPressEnter}
        placeholder={'Enter Email'}
        onChange={onChangeEmail}
        autoComplete="off"
        value={email}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
        endAdornment={
          orderFetchLoading && (
            <InputAdornment position="end">
              <CircularProgress size={18} color="primary" />
            </InputAdornment>
          )
        }
      />
      <Input
        className={classes.input}
        fullWidth={true}
        name="search"
        onKeyDown={onPressEnter}
        placeholder={
          type === 'verification'
            ? intl.formatMessage({ id: 'searchVerificationId' })
            : intl.formatMessage({ id: 'searchOrderId' })
        }
        onChange={onChangeText}
        autoComplete="off"
        value={input}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
        endAdornment={
          orderFetchLoading && (
            <InputAdornment position="end">
              <CircularProgress size={18} color="primary" />
            </InputAdornment>
          )
        }
      />
      <Popper
        open={open}
        anchorEl={anchorEl}
        role={undefined}
        transition
        disablePortal
        style={{
          backgroundColor: 'white',
          zIndex: 5000,
          width: 385,
          marginLeft: 20,
          marginTop: 20,
          marginRight: 20,
        }}
      >
        {({ TransitionProps, placement }: any) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow">
                  {orderData.map((item: any, index: number) => (
                    <MenuItem key={index} onClick={() => goTo(item.order_id)}>
                      <Box style={{ padding: 5 }}>
                        <Typography
                          style={{
                            fontSize: 13,
                            fontWeight: 'bold',
                            color: '#222b45',
                          }}
                        >{`Order ${item.o_id} - ${
                          item.date
                            ? moment(item.date).format('DD/MM/YY HH:mm')
                            : ''
                        }`}</Typography>
                        <Typography style={{ fontSize: 12, color: '#8f9bb3' }}>
                          {item.store_name}
                        </Typography>
                        <Box
                          style={{ position: 'absolute', right: 0, top: 18 }}
                        >
                          <ArrowRight />
                        </Box>
                      </Box>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {error && (
        <Box className={classes.errorMessageContainer}>
          <Alert
            className={classes.errorMessage}
            variant="filled"
            severity="error"
          >
            {errorMessage}
          </Alert>
        </Box>
      )}
    </Box>
  );
};

export default InputAndPopper;
