import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Box, Chip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: any) => ({
  subtitle2: {
    fontSize: '13px',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    marginLeft: 3,
  },
}));

const SelectedStoresView = ({ title, source = 'drawer' }: any) => {
  const { active_stores, selected_retailers, selected_regions } = useSelector(
    (state: any) => state.store,
  );
  const classes = useStyles();

  let storeChips = '',
    regionChips = '',
    retailerChips = '';
  if (active_stores.length > 0) {
    if (active_stores.length == 1) {
      storeChips = active_stores[0].retailer;
    } else if (active_stores.length > 1) {
      storeChips =
        active_stores[0].retailer +
        ' and ' +
        `${active_stores.length - 1}` +
        ' more ';
    }
  } else storeChips = '';

  if (selected_regions.length > 0) {
    if (selected_regions.length == 1) {
      regionChips = selected_regions[0].title;
    } else if (selected_regions.length > 1) {
      regionChips =
        selected_regions[0].title +
        ' and ' +
        `${selected_regions.length - 1}` +
        ' more ';
    }
  } else regionChips = '';
  if (selected_retailers.length > 0) {
    if (selected_retailers.length == 1) {
      retailerChips = selected_retailers[0].title;
    } else if (selected_retailers.length > 1) {
      retailerChips =
        selected_retailers[0].title +
        ' and ' +
        `${selected_retailers.length - 1}` +
        ' more ';
    }
  } else retailerChips = '';

  const displayChipOrText = (value: any) =>
    source === 'header' ? (
      <Typography className={classes.subtitle2}>{value} </Typography>
    ) : (
      <Chip label={value} style={{ marginRight: 8, marginTop: 4 }} />
    );

  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
    >
      {title ? title : <></>}
      {storeChips && displayChipOrText(storeChips)}{' '}
      {active_stores.length > 1 && (
        <>
          {regionChips && displayChipOrText(regionChips)}
          {retailerChips && displayChipOrText(retailerChips)}
        </>
      )}
    </Box>
  );
};

export const SelectedStores = memo(SelectedStoresView);
