import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import PrivateRoute from '../PrivateRoute';
import { Orders } from 'src/screens';
import Items from 'src/screens/Items';
import UserDisable from 'src/screens/users';

const HomeRouter = () => {
  return (
    <Switch>
      <PrivateRoute component={Orders} path="/dashboard/orders" exact />
      <PrivateRoute component={Items} path="/dashboard/items" exact />
      <PrivateRoute component={UserDisable} path="/dashboard/users" />
      <Redirect from="/dashboard" to="/dashboard/orders" />
    </Switch>
  );
};

export default HomeRouter;
