import React from 'react';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';
import PublicRoute from '../PublicRoute';
import PrivateRoute from '../PrivateRoute';
import { Home, Login, OrderDetails } from 'src/screens';

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Switch>
        <PublicRoute restricted={true} component={Login} path="/" exact />
        <PrivateRoute component={Home} path="/dashboard" />
        <PrivateRoute
          component={OrderDetails}
          path="/orderDetails/:order_id"
          exact
        />
        <Redirect from="*" to="/" />
      </Switch>
    </BrowserRouter>
  );
};

export default AppRouter;
