import React from 'react';
import { Paper, Box, IconButton, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import RefreshIcon from '../../assets/refresh.svg';
import clsx from 'clsx';
import { useTheme } from '@material-ui/styles';
import RefreshSvgComponentIcon from 'src/assets/svgIcons/RefreshSvgCompontIcon';

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    height: '79px',
    backgroundColor: theme.palette.color_white.main,
    borderBottom: `1px solid ${theme.palette.color_light_grey.main}`,
    borderRadius: 0,
  },
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },

  backArrow: {
    color: theme.palette.color_black.main,
  },

  title: {
    color: theme.palette.color_dark_text.main,
    fontWeight: 'bold',
    fontSize: 18,
  },

  titleMargin: {
    marginLeft: 20,
  },

  subtitle: {
    fontSize: 12,
    color: theme.palette.color_text_grey.main,
  },
  textContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginLeft: 10,
  },

  buttonLeft: {
    width: (props: any) => props.width,
    marginRight: 20,
    backgroundColor: (props: any) => `${theme.palette[props.b1bg].main}1A`,
    border: (props: any) => `1px solid ${theme.palette[props.b1bg].main}`,
    height: '40px',
    fontWeight: 'bold',
    borderRadius: 6,
    borderWidth: 1,
    color: (props: any) => theme.palette[props.b1bg].main,
  },
  buttonLeftRipple: {
    backgroundColor: (props: any) => `${theme.palette[props.b1bg].main}1A`,
  },
  icon: { width: 20, height: 20 },
}));

const NavigationTopBar = ({
  title = null,
  titlekey = null,
  defaultValue = null,
  arrowPresent = true,
  subtitle = null,
  refresh = false,
  onRefreshClick = null,
  rightComponent = () => <></>,
}: any) => {
  const theme: any = useTheme();
  const width = window.innerWidth < theme.breakpoints.values['sm'] ? 40 : 100;
  const classes = useStyles({ b1bg: 'info', width });
  const history = useHistory();
  let newClass = !arrowPresent
    ? clsx(classes.textContainer, classes.titleMargin)
    : classes.textContainer;

  return (
    <Paper className={classes.root}>
      <Box className={classes.container}>
        {arrowPresent && (
          <IconButton onClick={history.goBack}>
            <ArrowBackIcon className={classes.backArrow}></ArrowBackIcon>
          </IconButton>
        )}
        <Box className={newClass}>
          <Typography className={classes.title}>
            {titlekey && defaultValue && (
              <FormattedMessage
                id={titlekey}
                defaultMessage={defaultValue}
              ></FormattedMessage>
            )}
            {title ? title : null}
          </Typography>
          {subtitle && (
            <Typography className={classes.subtitle}>{subtitle}</Typography>
          )}
        </Box>
        {refresh && width > 40 && (
          <Button
            className={classes.buttonLeft}
            variant="outlined"
            onClick={onRefreshClick}
            startIcon={<RefreshSvgComponentIcon />}
            TouchRippleProps={{ classes: { root: classes.buttonLeftRipple } }}
          >
            <FormattedMessage
              id="refresh"
              defaultMessage="REFRESH"
            ></FormattedMessage>
          </Button>
        )}
        {refresh && width === 40 && (
          <IconButton
            onClick={onRefreshClick}
            className={classes.buttonLeft}
            TouchRippleProps={{ classes: { root: classes.buttonLeftRipple } }}
          >
            <img className={classes.icon} src={RefreshIcon} alt="refresh" />
          </IconButton>
        )}
        {rightComponent()}
      </Box>
    </Paper>
  );
};

export default NavigationTopBar;
