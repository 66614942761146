export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const CHANGE_THEME = 'CHANGE_THEME';
export const CHANGE_THEME_VARIENT = 'CHANGE_THEME_VARIENT';
export const SET_CURRENT_STORE_DATA = 'SET_CURRENT_STORE_DATA';
export const SET_STORES = 'SET_STORES';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const UPDATE_SCREEN = 'UPDATE_SCREEN';
export const UPDATE_NOTIFICATION_ID = 'UPDATE_NOTIFICATION_ID';
export const SET_ACTIVE_STORES = 'SET_ACTIVE_STORES';
export const SET_REGIONS = 'SET_REGIONS';
export const SET_RETAILERS = 'SET_RETAILERS';
export const SET_SELECTED_RETAILERS = 'SET_SELECTED_RETAILERS';
export const SET_SELECTED_REGIONS = 'SET_SELECTED_REGIONS';
export const CHANGE_START_DATE = 'CHANGE_START_DATE';
export const CHANGE_END_DATE = 'CHANGE_END_DATE';
export const SHOW_DEMO_STORES = 'SHOW_DEMO_STORES';
export const TOGGLE_INTRO = 'TOGGLE_INTRO';
export const CHANGE_ANALYTICS_START_DATE = 'CHANGE_ANALYTICS_START_DATE';
export const CHANGE_ANALYTICS_END_DATE = 'CHANGE_ANALYTICS_END_DATE';
export const CHANGE_PAGE_NUMBER = 'CHANGE_PAGE_NUMBER';
export const CHANGE_ROWS_PER_PAGE = 'CHANGE_ROWS_PER_PAGE';
export const CHANGE_DELIMITER = 'CHANGE_DELIMITER';
export const CHANGE_SETTLEMENTS_SELECTED_RETAILER =
  'CHANGE_SETTLEMENTS_SELECTED_RETAILER';
