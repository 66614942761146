import React, { useState, useEffect, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Hidden,
  Box,
  Grid,
  GridList,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import Header from '../../components/Header';
import { FormattedMessage, useIntl } from 'react-intl';
import { InputAndPopper, OrdersCard } from 'src/components';
import { Pagination } from '@material-ui/lab';
import { useTheme } from '@material-ui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { ordersApi } from 'src/services/orders';
import { useQuery } from 'react-query';
import { get } from 'lodash';
import { useCookies } from 'react-cookie';
import NoOrdersIcon from 'src/assets/no_orders.svg';
import { getSelectedStoreAppClipIds } from 'src/utils';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    height: '100vh',
  },
  bodyContainer: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      height: `calc(100% - ${136}px)`,
    },
    [theme.breakpoints.up('sm')]: {
      height: `calc(100% - ${90}px)`,
    },
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '10px',
    overflow: 'hidden',

    [theme.breakpoints.down('md')]: {
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
  body: {
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'scroll',
  },
  footer: {
    flex: 0.103,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    paddingTop: 8,
    paddingBottom: 8,
  },
  gridContainer: {
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      padding: 20,
    },
  },
  input: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  noOrdersContainer: {
    display: 'flex',
    width: '100%',
    padding: 30,
    flex: 0.897,
    justifyContent: 'center',
    alignItems: 'center',
  },
  noOrders: {
    width: 343,
    height: 367,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F6F9FC',
    flexDirection: 'column',
  },

  iconContainer: {
    width: 140,
    height: 140,
    borderRadius: 70,
    backgroundColor: '#EDF1F6',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  icon: {
    width: 80,
    height: 80,
  },

  noOrdersText: {
    marginTop: 10,
    fontSize: 15,
    fontWeight: 'bold',
    color: '#8f9bb3',
  },
}));

const Orders = () => {
  const classes = useStyles();
  const theme: any = useTheme();
  const [cookies] = useCookies();
  const dispatch = useDispatch();

  const { selected_regions, selected_retailers, stores, show_demo_stores } =
    useSelector((state: any) => state.store);
  const active_stores = useSelector((state: any) => state.store.active_stores);
  const { pageNumber, pageSize = 10 } = useSelector(
    (state: any) => state.orders,
  );
  const { orders: page = 1 } = pageNumber;

  const handlePagination = (event: any, value: any) => {
    dispatch({
      type: 'CHANGE_PAGE_NUMBER',
      payload: {
        ...pageNumber,
        orders: value,
      },
    });
  };

  const fetchOrdersData = async () => {
    let store_ids = getSelectedStoreAppClipIds(
      stores,
      selected_retailers,
      selected_regions,
      active_stores,
      show_demo_stores,
    );
    let regions = selected_regions.map((region: any) => region.value);
    let store_types = selected_retailers.map((retailer: any) => retailer.value);

    let params = {
      v1: true,
      store_types: store_types.join(),
      app_clip_ids: store_ids.join(),
      regions: regions.join(),
      page,
      purchase_type: 'scan_and_go',
      token: cookies.app_token,
      show_demo: show_demo_stores,
      page_size: pageSize,
    };
    return ordersApi.query(params);
  };

  const {
    isLoading,
    data: ordersData,
    isFetching,
  } = useQuery(
    [
      'ordersData',
      active_stores,
      page,
      selected_regions,
      selected_retailers,
      active_stores,
      pageSize,
    ],
    fetchOrdersData,
    {
      refetchOnWindowFocus: false,
    },
  );

  return (
    <Box className={classes.root}>
      <Header
        title={<FormattedMessage id="orders" defaultMessage="Orders" />}
        subtitle={
          <FormattedMessage
            id="showOrders"
            defaultMessage="Showing orders of"
          />
        }
      />
      <Hidden only={['lg', 'xl']}>
        <Box className={classes.input}>
          <InputAndPopper />
        </Box>
      </Hidden>
      <Box component="div" className={classes.bodyContainer}>
        {!isLoading && get(ordersData, 'data.data', []).length === 0 && (
          <Box className={classes.noOrdersContainer}>
            <Box className={classes.noOrders}>
              <Box className={classes.iconContainer}>
                <img className={classes.icon} src={NoOrdersIcon} alt="" />
              </Box>
              <Typography className={classes.noOrdersText}>
                No orders so far
              </Typography>
            </Box>
          </Box>
        )}
        {isLoading && (
          <Box className={classes.noOrdersContainer}>
            <CircularProgress color="primary" size={30} />
          </Box>
        )}
        <Box component="div" className={classes.body}>
          {get(ordersData, 'data.data', []).map((item: any, index: number) => (
            <OrdersCard item={item} />
          ))}
        </Box>
        {get(ordersData, 'data.data', []).length > 0 && (
          <Paper className={classes.footer}>
            <InputLabel htmlFor="age-simple" style={{ marginRight: 8 }}>
              No. of Items
            </InputLabel>
            <Select
              style={{ marginRight: 8 }}
              value={pageSize}
              onChange={(event: any) => {
                dispatch({
                  type: 'CHANGE_ROWS_PER_PAGE',
                  payload: event.target.value,
                });
              }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
            <Pagination
              count={Math.ceil(get(ordersData, 'data.count', 10) / pageSize)}
              shape="rounded"
              size="medium"
              color="primary"
              page={page}
              onChange={handlePagination}
            />
          </Paper>
        )}
      </Box>
    </Box>
  );
};

export default memo(Orders);
