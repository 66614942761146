import React from 'react';
import './App.css';

import { Provider } from 'react-redux';
import { store, persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ErrorBoundary, ThemeProvider } from './components';
import AppRouter from './router/AppRouter';
import LanguageProvider from './components/LanguageProvider';
import LogoutWrapper from './components/LogoutWrapper';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <div className="App">
      <LanguageProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <QueryClientProvider client={queryClient}>
              <ThemeProvider>
                <LogoutWrapper persistor={persistor}>
                  <ErrorBoundary>
                    <AppRouter />
                  </ErrorBoundary>
                </LogoutWrapper>
              </ThemeProvider>
            </QueryClientProvider>
          </PersistGate>
        </Provider>
      </LanguageProvider>
    </div>
  );
}

export default App;
