import { get } from 'lodash';
import React from 'react';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const [cookies] = useCookies();
  const { active_stores } = useSelector((state: any) => state.store);

  const routeToScreens = (props: any) => {
    const path = props?.location?.pathname;
    let clickAndCollectStore = active_stores.find((store: any) =>
      get(store, 'features_applicable.click_and_collect', false),
    );
    const isClickAndCollect = get(
      clickAndCollectStore,
      'features_applicable.click_and_collect',
      false,
    );
    if (path === '/dashboard/click_and_collect' && isClickAndCollect) {
      return cookies.app_token ? <Component {...props} /> : <Redirect to="/" />;
    } else if (path === '/dashboard/click_and_collect' && !isClickAndCollect) {
      return <Redirect to="/" />;
    } else {
      return cookies.app_token ? <Component {...props} /> : <Redirect to="/" />;
    }
  };
  return <Route {...rest} render={(props: any) => routeToScreens(props)} />;
};

export default PrivateRoute;
