import produce from 'immer';
import {
  CHANGE_PAGE_NUMBER,
  CHANGE_ROWS_PER_PAGE,
} from '../actions/action_types';

const INITIAL_STATE = {
  pageNumber: 1,
  pageSize: 10,
};

const orders = produce((draft: any, action: any) => {
  switch (action.type) {
    case CHANGE_PAGE_NUMBER:
      draft.pageNumber = action.payload;
      break;
    case CHANGE_ROWS_PER_PAGE:
      draft.pageSize = action.payload;
      break;
  }
}, INITIAL_STATE);

export default orders;
