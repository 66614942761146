import React, { useState, useEffect, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useQuery } from 'react-query';
import Header from '../../components/Header';
import { Box, Button, CircularProgress, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Alert } from '@material-ui/lab';
import { useMutation } from 'react-query';
import { get } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { getSelectedStoreAppClipIds } from 'src/utils';
import { ordersApi } from 'src/services/orders';
import { useCookies } from 'react-cookie';
import { Editor } from 'src/components/Editor';
import axios from "axios";


const useStyles = makeStyles((theme: any) => ({
  input: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    width: 343,
    height: 40,
    display: 'flex',
    alignSelf: 'center',
    marginRight: 8,
  },
  inputEmail: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    width: 343,
    height: 40,
    display: 'flex',
    alignSelf: 'center',
    marginRight: 8,
  },
  errorMessageContainer: {
    width: '100%',
    position: 'absolute',
    bottom: 100,
    [theme.breakpoints.between('lg', 'xl')]: {
      left: 150,
    },
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    zIndex: 5000,
  },
  errorMessage: { maxWidth: 343, flex: 1, fontWeight: 'bold' },
}));

const UserDisable = (props: any) => {
  const classes = useStyles();
  const {
    selected_regions,
    selected_retailers,
    stores,
    show_demo_stores,
    active_stores,
  } = useSelector((state: any) => state.store);

  
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Error');
  const [data, setData] = useState({});
  const [cookies] = useCookies();
  const [screenloading, setLoader] = useState(false);

  const onSearchExecuted =() => { 
    setLoader(true);
    const baseURL = "https://test.mishipay.com/dashboard-management/v1/user/deactivate/";
    axios.post(baseURL, { email: email }, {
      headers:{
        "Authorization": 'Token ' + cookies.app_token
      }  

   }).then((data)=>{
     setLoader(false);
    setData(data)
    // console.log(data)

   }).catch((e)=>{
    // console.log({e});
    setLoader(false); 
   })
  } 

return (
  
    <Box style={{ height: '100vh', width: '100%', overflow: 'scroll' }}>
      <Header
        title="User Disable"
       
        rightAccessory={() => {
          return (
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              
              <TextField
                label="Enter User Mail ID"
                value={email}
                onChange={(event: any) => {
                  setEmail(event.target.value);
                }}
                variant="outlined"
                size={'small'}
                style={{ width: 250, marginTop: 6, marginRight: 8 }}
              />
              <Button
                variant="contained"
                color="primary"
                style={{ marginTop: 6 }}
                onClick={() => onSearchExecuted()}
              >
                Disable
              </Button>
            </Box>
          );
        }}
      />
    
    {screenloading ? (
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress color="primary" size={30} />
        </Box>
      ) : (
        <Box style={{ height: 'inherit' }}>
          {data ? (
            <Editor mode="json" value={data} height={500} />
          ) : (
            <Box
              style={{
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
                height: '70vh',
              }}
            >
              { <p> No data available to show</p> }
            </Box>
          )}
        </Box>
      )}

      {error && (
        <Box className={classes.errorMessageContainer}>
          <Alert
            className={classes.errorMessage}
            variant="filled"
            severity="error"
          >
            {errorMessage}
          </Alert>
        </Box>
      )}
    
    </Box>
  );
};

export default UserDisable;
