import produce from 'immer';
import {
  UPDATE_NOTIFICATION_ID,
  UPDATE_SCREEN,
  TOGGLE_INTRO,
} from '../actions/action_types';

const INITIAL_STATE = {
  screenName: '',
  notifications: [],
  introVisible: true,
};

const screens = produce((draft, action) => {
  switch (action.type) {
    case UPDATE_SCREEN:
      draft.screenName = action.payload;
      break;
    // case UPDATE_NOTIFICATION_ID:
    //   draft.notifications.push(action.payload);
    //   break;
    case TOGGLE_INTRO:
      draft.introVisible = action.payload;
      break;
  }
}, INITIAL_STATE);

export default screens;
