import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Box,
  List,
  ListItem,
  IconButton,
  Typography,
  Button,
  Divider,
  DialogTitle,
  DialogContent,
  DialogActions,
  Input,
  InputAdornment,
  TextField,
  Checkbox,
  Snackbar,
  Hidden,
  CircularProgress,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from 'src/assets/svgIcons/SearchIcon';
import CloseIcon from 'src/assets/svgIcons/CloseIcon';
import ArrowDownIcon from 'src/assets/svgIcons/ArrowDownIcon';
import { remove, uniqBy, startCase, get } from 'lodash';
import SystemCheckbox from 'src/components/SystemCheckbox';
import { useMutation } from 'react-query';
import { storesApi } from 'src/services/user';
import { useCookies } from 'react-cookie';
import { Alert } from '@material-ui/lab';
import { FormattedMessage, useIntl } from 'react-intl';

const useStyles = makeStyles((theme: any) => ({
  list: {
    padding: 0,
    flex: 1,
  },
  listItem: {
    height: '64px',
  },
  listItemText: {
    color: theme.palette.color_dark_text.main,
    fontWeight: 'bold',
    fontSize: 15,
  },
  listItemTextDemo: {
    color: theme.palette.color_text_grey.main,
    fontWeight: 'bold',
    fontSize: 15,
    marginLeft: 8,
  },
  drawerContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingTop: 16,
    paddingLeft: 16,
    paddingRight: 16,
  },
  selectStore: { marginBottom: 8, fontSize: 13 },
  storeValueContainer: {
    display: 'flex',
    width: '100%',
  },
  refreshButton: {
    width: 40,
    height: 40,
    border: `solid 1px ${theme.palette.primary.main}`,
    backgroundColor: 'rgba(51, 102, 255, 0.1)',
  },
  icon: { width: 20, height: 20 },
  listContentContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  listContentIcon: { width: 20, height: 20, marginRight: 20 },
  listContentText: {
    color: theme.palette.color_dark_text.main,
    fontWeight: 'bold',
    fontSize: 15,
  },
  dialogContent: {
    minHeight: 400,
    maxHeight: 400,
  },
  filterLayoutRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  filterLayoutCol: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  regionInput: {
    marginTop: 16,
    maxHeight: 100,
    width: '100%',
  },
  retailerInput: {
    marginTop: 16,
    marginRight: 8,
    width: '100%',
  },
  errorMessageContainer: {
    width: '100%',
    position: 'absolute',
    bottom: 100,
    display: 'flex',
    justifyContent: 'center',
    zIndex: 2000,
  },
  errorMessage: { maxWidth: 343, flex: 1, fontWeight: 'bold' },
}));

const StoreSelector = ({ onClose }: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [cookies] = useCookies();
  const intl = useIntl();

  const {
    stores,
    active_stores = [],
    retailers,
    regions,
    selected_regions = [],
    selected_retailers = [],
    show_demo_stores,
  } = useSelector((state: any) => state.store);
  const {
    userData: { email, username },
    permissions: { allow_settlements },
  } = useSelector((state: any) => state.user);

  let retailersState: any = useMemo(
    () => [
      { value: 'AllRetailers', title: 'All Retailers', type: 'All' },
      ...retailers.map((item: any) => {
        return { ...item, type: 'single' };
      }),
    ],
    [retailers],
  );

  let regionState: any = useMemo(
    () => [
      { value: 'AllRegions', title: 'All Regions', type: 'All' },
      ...regions.map((item: any) => {
        return { ...item, type: 'single' };
      }),
    ],
    [regions],
  );

  const [availableRetailers, setRetailers] = useState(retailersState);
  const [availableRegions, setRegions] = useState(regionState);

  const [selectedRetailers, setSelectedRetailers] =
    useState<any>(selected_retailers);
  const [selectedRegions, setSelectedRegion] = useState<any>(selected_regions);
  const [selectedStores, setSelectedStores] = useState(active_stores);
  const [selectAllStores, setSelectAllStores] = useState(false);
  const [data, setData] = useState(stores);
  const [input, setInput] = useState('');
  const [toast, showToast] = useState(false);
  const [showDemoStores, setShowDemoStores] = useState(show_demo_stores);
  const [errorMessage, setErrorMessage] = useState('Error');
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleClose = () => {
    showToast(false);
  };

  const executeSearch = (item: any, query: any) => {
    return item.retailer.toLowerCase().includes(query.toLowerCase());
  };

  const filterDemoStores = useCallback(() => {
    return showDemoStores
      ? stores
      : stores.filter((item: any) => {
          return item.store_id && !item.demo && item.active;
        });
  }, [showDemoStores]);

  const filterStoresByRetailer = useCallback(
    (retailers: any = selectedRetailers) =>
      retailers.length
        ? filterDemoStores().filter((store: any) => {
            return retailers.some((item: any) => {
              if (
                store?.store_type
                  ?.toLowerCase()
                  .includes(item.value?.toLowerCase())
              )
                return true;
              else return false;
            });
          })
        : filterDemoStores(),
    [showDemoStores, selectedRetailers, selectedRegions],
  );

  const filterStoresByRegion = useCallback(
    (regions: any = selectedRegions, retailers: any = selectedRetailers) =>
      regions.length
        ? filterStoresByRetailer(retailers).filter((store: any) => {
            return regions.some((item: any) => {
              if (
                store?.region?.toLowerCase().includes(item.value?.toLowerCase())
              )
                return true;
              else return false;
            });
          })
        : filterStoresByRetailer(retailers),
    [showDemoStores, selectedRetailers, selectedRegions],
  );

  const onChangeText = (e: any) => {
    let query = e.target.value;
    setInput(query);
    setSelectAllStores(false);
    let textFilteredStores = filterStoresByRegion().filter((item: any) =>
      executeSearch(item, query),
    );
    setData([...selectedStores, ...textFilteredStores]);
  };

  const clearAll = () => {
    setSelectAllStores(false);
    setSelectedStores([]);
    setSelectedRetailers([]);
    setSelectedRegion([]);
  };

  const onDemoStoreToggle = () => {
    clearAll();
    let filterStores: any = showDemoStores
      ? stores.filter((item: any) => {
          return item.store_id && !item.demo && item.active;
        })
      : stores;

    let filterRetailers: any = uniqBy(
      filterStores
        .map((item: any) => {
          if (item.store_type) {
            return {
              title: startCase(item.store_type.replace('StoreType', '')),
              value: item.store_type,
            };
          }
        })
        .filter((item: any) => item && item.value),
      'value',
    );
    setRetailers(filterRetailers);
    setShowDemoStores(() => !showDemoStores);
  };

  const getSelectedRetailer = (
    event: any,
    value: any,
    reason: any,
    details?: any,
  ) => {
    if (details?.option?.type === 'All' || !details) {
      //select all
      if (reason === 'select-option') {
        if (selectedRetailers.length) {
          //toggle
          setSelectedRetailers([]);
          setSelectedStores([]);
          setSelectedRegion([]);
          return [];
        } else {
          // if items already selected then set empty else set all
          setSelectedStores(stores);
          setSelectAllStores(true);
          return availableRetailers;
        }
      } else if (reason === 'remove-option' || reason === 'clear') {
        setSelectedStores([]);
        setSelectedRegion([]);
        return [];
      } //remove all
    } else if (
      // check if user manually selected all and enable select all
      reason === 'select-option'
    ) {
      if (
        availableRetailers.length - [...selectedRetailers, value].length ===
        1
      ) {
        setSelectedStores(filterStores(availableRetailers, selectedRegions));
        return availableRetailers;
      } else {
        setSelectedStores(filterStores(value, selectedRegions));
        return value;
      }
    } else if (
      reason === 'remove-option' //put select all in indeterminate state if items unselected from select all state
    ) {
      if (availableRetailers.length === selectedRetailers.length) {
        return remove([...value], (i: any) => i.type !== 'All');
      } else {
        setSelectedStores(() => [
          ...selectedStores.filter(
            (item: any) => item.store_type !== details.option.value,
          ),
        ]);
        return value;
      }
    } else return value; // default toggle selection
  };

  const onRetailerChange = (
    event: any,
    value: any,
    reason: any,
    details?: any,
  ) => {
    event.stopPropagation();
    setSelectAllStores(false);
    let retailerValue = getSelectedRetailer(event, value, reason, details);
    setSelectedRetailers(retailerValue);
    if (retailerValue.length) {
      let regions = filterStoresByRetailer(retailerValue).map((store: any) => ({
        value: store.region,
        title: store.region,
        type: 'single',
      }));
      setRegions(() =>
        uniqBy(
          [
            { value: 'AllRegions', title: 'All Regions', type: 'All' },
            ...regions,
          ],
          'value',
        ),
      );
      //if selected region does not exist in the link remove from the selection .
    } else setRegions(regionState);
    setSelectedRegion([]);
  };

  const onRegionChange = (
    event: any,
    value: any,
    reason: any,
    details?: any,
  ) => {
    event.stopPropagation();
    setSelectAllStores(false);
    if (details?.option?.type === 'All' || !details) {
      //select all
      if (reason === 'select-option') {
        selectedRegions.length
          ? setSelectedRegion([])
          : setSelectedRegion(availableRegions); // if items already selected then set empty else set all
        setSelectedStores(filterStoresByRegion(availableRegions));
      } else if (reason === 'remove-option' || reason === 'clear') {
        setSelectedRegion([]); //remove all
        setSelectedStores(filterStoresByRetailer());
      }
    } else if (
      // check if user manually selected all and enable select all
      reason === 'select-option'
    ) {
      if (availableRegions.length - [...selectedRegions, value].length === 1) {
        setSelectedRegion(availableRegions);
        setSelectedStores(filterStores(selectedRetailers, availableRegions));
      } else {
        setSelectedRegion(value);
        setSelectedStores(() => filterStores(selectedRetailers, value));
      }
    } else if (
      //put select all in indeterminate state if items unselected from select all state
      reason === 'remove-option'
    ) {
      if (availableRegions.length === selectedRegions.length) {
        setSelectedRegion(remove([...value], (i: any) => i.type !== 'All'));
      } else {
        setSelectedRegion(value);
        setSelectedStores(() => [
          ...selectedStores.filter(
            (item: any) => item.region !== details.option.value,
          ),
        ]);
      }
    } else setSelectedRegion(value); // defalult toggle selection
  };

  const checkSelected = (val: any) =>
    selectedStores.some((a: any) => a.store_id === val.store_id);

  const findValueIndex = (val: any) =>
    selectedStores.findIndex((a: any) => a.store_id === val.store_id);

  const onStoreSelect = (flag: boolean, val: any) => {
    let arr: any = [];
    if (!flag) {
      arr = [...selectedStores];
      arr.splice(findValueIndex(val), 1);
    } else arr = [...selectedStores, val];
    setSelectedStores(arr);
  };

  const saveChanges = () => {
    console.log({ selectedRetailers, selectedStores });

    if (selectedStores.length > 0) {
      allow_settlements &&
        dispatch({
          type: 'CHANGE_SETTLEMENTS_SELECTED_RETAILER',
          payload: {
            title: startCase(
              selectedStores[0]?.store_type?.replace('StoreType', ''),
            ),
            value: selectedStores[0]?.store_type,
          },
        });
      dispatch({ type: 'SET_SELECTED_RETAILERS', payload: selectedRetailers });
      dispatch({ type: 'SET_ACTIVE_STORES', payload: selectedStores });
      dispatch({ type: 'SET_SELECTED_REGIONS', payload: selectedRegions });
      dispatch({
        type: 'SHOW_DEMO_STORES',
        payload: showDemoStores,
      });
      dispatch({
        type: 'CHANGE_PAGE_NUMBER',
        payload: {
          orders: 1,
          ccOrders: 1,
        },
      });
      onClose();
    } else showToast(true);
  };

  const filterStores = (selectedRetailers: any, selectedRegions: any) => {
    return filterStoresByRegion(selectedRegions, selectedRetailers);
  };

  const refreshStores = () =>
    storesApi.query({ v1: true, token: cookies.app_token, show_demo: true });

  const { isLoading, mutate: getStores } = useMutation(refreshStores, {
    onError: (serverError: any) => {
      setError(true);
      setErrorMessage(serverError);
    },
    onSuccess: successData => {
      let storesData = get(successData, 'data.data', []).filter((item: any) => {
        return !item.retailer.split(' ').includes('All');
      });

      let regions = uniqBy(
        storesData
          .map((item: any) => {
            if (item.region) {
              return {
                title: item.region,
                value: item.region,
              };
            }
          })
          .filter((item: any) => item && item.value),
        'value',
      );
      let retailers = uniqBy(
        storesData
          .map((item: any) => {
            if (item.store_type) {
              return {
                title: startCase(item.store_type.replace('StoreType', '')),
                value: item.store_type,
              };
            }
          })
          .filter((item: any) => item && item.value),
        'value',
      );
      dispatch({
        type: 'SET_STORES',
        payload: storesData,
      });

      dispatch({
        type: 'SET_REGIONS',
        payload: regions,
      });
      dispatch({
        type: 'SET_RETAILERS',
        payload: retailers,
      });
      setData(storesData);
      setSuccess(true);
    },
  });

  useEffect(() => {
    setData(() => uniqBy(filterStoresByRegion(), 'store_id'));
  }, [selectedRetailers, selectedRegions, showDemoStores]);

  useEffect(() => {
    selectedStores.length === filterStoresByRegion().length
      ? setSelectAllStores(true)
      : setSelectAllStores(false);
  }, [selectedStores]);

  useEffect(() => {
    let timer: any = null;
    if (error) {
      timer = setTimeout(() => {
        setError(false);
        setErrorMessage('Error');
      }, 3000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [error]);

  const renderFilters = () => {
    return (
      <>
        <Autocomplete
          multiple
          limitTags={2}
          id="retailers"
          options={availableRetailers}
          disableCloseOnSelect
          size="small"
          value={selectedRetailers}
          getOptionLabel={(option: any) => option?.title}
          onChange={onRetailerChange}
          renderOption={(option, { selected }) => {
            let indeterminate = false;
            if (
              selectedRetailers.length &&
              !selectedRetailers.some((item: any) => item.type === 'All')
            ) {
              indeterminate = true;
            } else if (
              selectedRetailers.length === availableRetailers.length - 1 &&
              !selectedRetailers.some((item: any) => item.type === 'All')
            ) {
              indeterminate = false;
              selected = true;
            }
            return (
              <React.Fragment>
                <SystemCheckbox
                  color="primary"
                  option={option}
                  selected={selected}
                  indeterminate={indeterminate}
                />
              </React.Fragment>
            );
          }}
          getOptionSelected={(option: any, value: any) => {
            return option?.value === value?.value;
          }}
          popupIcon={<ArrowDownIcon />}
          className={classes.retailerInput}
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              label="Filter by Retailer"
              placeholder="Select Retailers"
            />
          )}
        />
        <Autocomplete
          multiple
          id="regions"
          options={availableRegions}
          disableCloseOnSelect
          size="small"
          value={selectedRegions}
          limitTags={2}
          getOptionLabel={(option: any) => option?.title}
          onChange={onRegionChange}
          renderOption={(option, { selected }) => {
            let indeterminate = false;
            if (
              selectedRegions.length &&
              !selectedRegions.some((item: any) => item.type === 'All')
            ) {
              indeterminate = true;
            } else if (
              selectedRegions.length === availableRegions.length - 1 &&
              !selectedRegions.some((item: any) => item.type === 'All')
            ) {
              indeterminate = false;
              selected = true;
            }
            return (
              <React.Fragment>
                <SystemCheckbox
                  color="primary"
                  option={option}
                  selected={selected}
                  indeterminate={indeterminate}
                />
              </React.Fragment>
            );
          }}
          getOptionSelected={(option: any, value: any) => {
            return option?.value === value?.value;
          }}
          popupIcon={<ArrowDownIcon />}
          className={classes.regionInput}
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              label="Filter by Region"
              placeholder="Select Region"
              style={{ maxHeight: 20 }}
            />
          )}
        />
      </>
    );
  };

  return (
    <Box>
      <DialogTitle>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Typography> Select Region, Retailer and Store</Typography>
          <Button
            startIcon={isLoading ? <CircularProgress size={25} /> : <></>}
            color="primary"
            style={{ textTransform: 'none' }}
            onClick={() => getStores()}
          >
            Refresh Store listing
          </Button>
        </Box>

        <Input
          value={input}
          fullWidth={true}
          name="search"
          placeholder="Search"
          onChange={onChangeText}
          autoComplete="off"
          style={{ marginTop: 8 }}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          endAdornment={
            input ? (
              <InputAdornment position="start">
                <IconButton
                  onClick={() => {
                    onChangeText({ target: { value: '' } });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            ) : (
              <></>
            )
          }
        />

        <Hidden only={['xs', 'sm', 'md']}>
          <Box className={classes.filterLayoutRow}>{renderFilters()}</Box>
        </Hidden>
        <Hidden only={['lg', 'xl']}>
          <Box className={classes.filterLayoutCol}>{renderFilters()}</Box>
        </Hidden>
        {(email && email.includes('mishipay')) ||
        username.includes('-demo-user') ? (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Checkbox
              name="Show demo stores"
              checked={showDemoStores}
              onChange={onDemoStoreToggle}
              color="primary"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
            <Typography>Show Demo stores</Typography>
          </Box>
        ) : (
          <></>
        )}
      </DialogTitle>

      <DialogContent dividers={true} className={classes.dialogContent}>
        {data && data.length ? (
          <List className={classes.list}>
            <ListItem
              onClick={() => {
                setSelectAllStores(!selectAllStores);
                setSelectedStores(!selectAllStores ? data : []);
              }}
              button
              className={classes.listItem}
            >
              <Checkbox
                color="primary"
                checked={selectAllStores}
                onChange={(event: any) => {
                  setSelectAllStores(event?.target.checked);
                  setSelectedStores(event?.target.checked ? data : []);
                }}
                inputProps={{ 'aria-label': 'indeterminate checkbox' }}
                style={{ marginRight: 8 }}
              />
              <Typography className={classes.listItemText}>
                All Stores
              </Typography>
            </ListItem>
            {data.map((item: any, index: number) => {
              let status = [];
              item.demo && status.push('DEMO');
              !item.active && status.push('INACTIVE');
              return (
                <Box component="div" key={index}>
                  <ListItem
                    onClick={() => {
                      onStoreSelect(!checkSelected(item), item);
                    }}
                    button
                    key={index}
                    className={classes.listItem}
                  >
                    <Checkbox
                      color="primary"
                      checked={checkSelected(item)}
                      onChange={() => onStoreSelect(!checkSelected(item), item)}
                      inputProps={{ 'aria-label': 'indeterminate checkbox' }}
                      style={{ marginRight: 8 }}
                    />
                    <Typography className={classes.listItemText}>
                      {item.retailer}
                    </Typography>
                    <Typography className={classes.listItemTextDemo}>
                      {status.length > 0 ? status.join(' | ') : ''}
                    </Typography>
                  </ListItem>
                  <Divider light />
                </Box>
              );
            })}
          </List>
        ) : (
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography>
              No stores available. Clear filters and try again.
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Box
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button onClick={clearAll} color={'secondary'}>
            Clear All
          </Button>
          <Box>
            <Button
              onClick={() => {
                onClose();
              }}
              style={{ marginRight: 8, color: '#ffaa00' }}
            >
              Cancel
            </Button>
            <Button onClick={saveChanges} color="primary" variant="contained">
              Select Stores{' '}
              {selectedStores.length ? '( ' + selectedStores.length + ' )' : ''}
            </Button>
          </Box>
        </Box>
      </DialogActions>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={toast}
        autoHideDuration={3000}
        onClose={handleClose}
        message="Please select a store"
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
      />
      {error && (
        <Box className={classes.errorMessageContainer}>
          <Alert
            className={classes.errorMessage}
            variant="filled"
            severity="error"
          >
            {errorMessage}
          </Alert>
        </Box>
      )}
      {success && (
        <Box className={classes.errorMessageContainer}>
          <Alert
            className={classes.errorMessage}
            variant="filled"
            severity="success"
          >
            {intl.formatMessage({ id: 'updateStores' })}
          </Alert>
        </Box>
      )}
    </Box>
  );
};

export default React.memo(StoreSelector);
