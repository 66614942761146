import React from 'react';
import AceEditor from 'react-ace';

import 'brace/ext/searchbox';
import 'brace/mode/html';
import 'brace/mode/json';
import 'brace/mode/javascript';
import 'brace/mode/markdown';
import 'brace/mode/text';
import 'brace/theme/textmate';

export const Editor = (props: any) => {
  let { disable, errors, showError, value, height = 300, ...rest } = props;

  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <AceEditor
        {...rest}
        readOnly={disable}
        value={JSON.stringify(value, null, 4)}
        style={{
          backgroundColor: 'inherit',
          margin: 8,
          height: 400,
          borderWidth: 1,
        }}
      />
    </div>
  );
};

Editor.defaultProps = {
  errors: [],
  fontSize: 14,
  height: '100%',
  minLines: 7,
  showError: true,
  showPrintMargin: false,
  theme: 'textmate',
  width: '100%',
  wrapEnabled: true,
};
