import React, { useState, memo, useEffect } from 'react';
import {
  Paper,
  Box,
  Button,
  CircularProgress,
  Typography,
  List,
  ListItem,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Hidden,
  LinearProgress,
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Snackbar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { NavigationTopBar } from 'src/components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { get, startCase } from 'lodash';
import {
  orderDetailApiV2,
  orderStatusApi,
  promotedOrderApi,
  refundOrder,
  refundOrderNew,
} from 'src/services/orders';
import { changeCCOrderStatusApi } from 'src/services/clickAndCollect';
import {
  constructUIData,
  getCurrency,
  getOrderCount,
  orderStatus,
} from 'src/utils';
import moment from 'moment';
import BarcodeShow from 'src/assets/svgIcons/BarcodeShow';
import RefundSelector from './components/RefundSelector';
import { useImmer } from 'use-immer';
// @ts-ignore
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Alert } from '@material-ui/lab';
import { Editor } from 'src/components/Editor';

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    height: '100vh',
  },
  header: {},
  body: {
    flex: 1,
    overflow: 'scroll',
    marginBottom: 92,
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: 84,
    backgroundColor: 'white',
  },
  statusButton: {
    height: 40,
    width: '100%',
    [theme.breakpoints.between('lg', 'xl')]: {
      width: 210,
    },
    fontWeight: 'bold',
    backgroundColor: (props: any) =>
      `${theme.palette[props.color ? props.color : 'info'].main}${
        props.variant === 'outlined' ? '33' : ''
      }`,
    border: (props: any) =>
      `solid 1px ${theme.palette[props.color ? props.color : 'info'].main}`,
    textTransform: 'none',
    color: (props: any) =>
      props.variant === 'outlined'
        ? theme.palette[props.color ? props.color : 'info'].main
        : theme.palette.color_white.main,
    fontSize: 15,
  },

  buttonLeft: {
    width: '167px',
    backgroundColor: (props: any) => `${theme.palette[props.b1bg].main}1A`,
    border: (props: any) => `1px solid ${theme.palette[props.b1bg].main}`,
    height: '40px',
    fontWeight: 'bold',
    borderWidth: 1,
    color: (props: any) => theme.palette[props.b1bg].main,
  },

  ccButtonLeft: {
    backgroundColor: (props: any) => `${theme.palette.primary.main}1A`,
    border: (props: any) => `1px solid ${theme.palette.primary.main}`,
    height: '40px',
    fontWeight: 'bold',
    borderWidth: 1,
    color: (props: any) => theme.palette.primary.main,
    '&:hover': {
      backgroundColor: (props: any) => `${theme.palette.primary.main}1A`,
    },
  },

  buttonRefundLeft: {
    width: '167px',
    backgroundColor: (props: any) => `${theme.palette.primary.main}1A`,
    border: (props: any) => `1px solid ${theme.palette.primary.main}`,
    height: '40px',
    fontWeight: 'bold',
    borderWidth: 1,
    color: (props: any) => theme.palette.primary.main,
  },

  buttonRight: {
    width: '167px',
    backgroundColor: (props: any) => `${theme.palette[props.b2bg].main}`,
    color: theme.palette.color_white.main,
    marginLeft: 16,
    height: '40px',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: (props: any) => `${theme.palette[props.b2bg].main}`,
    },
  },

  ccButtonRight: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.color_white.main,
    marginLeft: 16,
    height: '40px',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: (props: any) => `${theme.palette.primary.main}`,
    },
  },

  buttonRefundRight: {
    width: '167px',
    backgroundColor: (props: any) => `${theme.palette.primary.main}`,
    color: theme.palette.color_white.main,
    marginLeft: 16,
    height: '40px',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: (props: any) => `${theme.palette.primary.main}`,
    },
  },

  buttonLeftRipple: {
    backgroundColor: (props: any) => `${theme.palette[props.b1bg].main}1A`,
  },
  buttonRightRipple: {
    color: (props: any) => `${theme.palette[props.b2bg].main}`,
  },

  buttonRefundLeftRipple: {
    backgroundColor: (props: any) => `${theme.palette.primary.main}1A`,
  },
  buttonRefundRightRipple: {
    color: (props: any) => `${theme.palette.primary.main}`,
  },
  loader: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  bodyContentContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },

  orderSummary: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    padding: 16,
    alignItems: 'center',
    backgroundColor: '#f2f6ff',
    marginTop: '1px',
  },

  orderSummaryContent: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 16,
    [theme.breakpoints.between('lg', 'xl')]: {
      marginLeft: 16,
    },
    [theme.breakpoints.between('xs', 'md')]: {
      marginTop: 8,
    },
    alignItems: 'flex-start',
  },

  orderSummaryListContent: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 16,
    alignItems: 'flex-start',
    padding: 2,
  },

  lightGreyTextList: {
    fontSize: 12,
    color: theme.palette.color_text_grey.main,
  },
  darkTextList: {
    fontSize: 12,
    color: theme.palette.color_dark_text.main,
  },

  lightGreyText: {
    fontSize: 13,
    color: theme.palette.color_text_grey.main,
  },
  darkText: {
    fontSize: 13,
    color: theme.palette.color_dark_text.main,
  },
  outerBody: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
  },

  innerBody: {
    display: 'flex',
    maxWidth: 1160,
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingLeft: 16,
    paddingRight: 16,
  },

  orderSummaryText: {
    fontSize: 22,
    color: theme.palette.color_dark_text.main,
    fontWeight: 'bold',
    marginTop: 8,
  },

  orderSummaryUI: {
    width: '100%',
    height: '100%',
  },

  promotedLayout: {
    marginTop: '8px',
  },

  itemImage: {
    width: '38px',
    height: '38px',
    objectFit: 'contain',
  },

  itemImageContainer: {
    width: '38px',
    height: '38px',
    marginRight: 16,
  },

  imageAndtitleContainer: {
    display: 'flex',
  },
  itemName: {
    fontSize: '13px',
    color: theme.palette.color_dark_text.main,
  },
  itemCategory: {
    fontSize: '12px',
    color: theme.palette.color_text_grey.main,
  },

  promoBox: {
    marginTop: 8,
    display: 'flex',
  },

  promoText: {
    backgroundColor: theme.palette.promo_background.main,
    padding: '2px 10px',
    fontSize: '12px',
    color: theme.palette.color_dark_text,
  },

  barcodeShow: {
    color: theme.palette.primary.main,
    textTransform: 'none',
    fontSize: '12px',
    marginRight: 50,
  },

  buttonContainer: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '16px',
    },
    display: 'flex',
  },

  mobileLoaderContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  mobileLoader: {
    width: 200,
    height: 100,
    backgroundColor: '#CCCCCC',
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },

  circle: {
    marginRight: 20,
  },

  arrow: {
    overflow: 'hidden',
    position: 'absolute',
    width: '1em',
    height: '0.71em' /* = width / sqrt(2) = (length of the hypotenuse) */,
    boxSizing: 'border-box',
    color: theme.palette.background.paper,
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: '100%',
      height: '100%',
      boxShadow: theme.shadows[1],
      backgroundColor: 'currentColor',
      transform: 'rotate(45deg)',
      marginTop: -6,
      marginLeft: -2,
    },
  },
}));

const OrderDetails = () => {
  const theme: any = useTheme();
  const history: any = useHistory();
  const [cookies] = useCookies();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const intl = useIntl();
  let props_order_id = '';
  let type: any = null;
  let from = null;

  if (history && history.location && history.location.state) {
    props_order_id = history.location.state.order_id
      ? history.location.state.order_id
      : '';
    type = history.location.state.type ? history.location.state.type : null;
    from = history.location.state.from ? history.location.state.from : null;
  } else {
    props_order_id = history.location.pathname.split('/')[2];
  }

  let barcodeInit = { visible: false, type: '', data: '' };

  const [items_to_refund, setItemsToRefund] = useImmer([]);
  const [alertVisible, showAlert] = useState(false);
  const [ccAlertVisible, showCCAlert] = useState(false);
  const [barcode, showBarcode] = useState(barcodeInit);
  const [shouldRefundAll, setRefundAll] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [arrowRef, setArrowRef] = React.useState<HTMLElement | null>(null);
  const [active_store, setActiveStore] = useState({
    promo_version: 1,
    store_id: '',
    is_refund_allow: false,
    currency: 'gbp',
    retailer: '',
  });
  const [is_refund_allow, setRefundAllow] = useState(false);
  const [toast, showToast] = useState<any>({
    visible: false,
    message: '',
    severity: 'error',
  });
  const [cancelCCOrder, setCancelCCOrder] = React.useState(false);

  let { active_stores, show_demo_stores } = useSelector(
    (state: any) => state.store,
  );
  let { can_refund } = useSelector((state: any) => state.user.permissions);

  const fetchOrderDetailsV2 = () => {
    return orderDetailApiV2
      .get({
        order_id: props_order_id,
        v1: true,
        token: cookies.app_token,
        show_demo: show_demo_stores,
      })
      .catch((e: Error) => {
        console.log({ e });
        throw e;
      });
  };

  const {
    isLoading,
    error,
    data: orderResponse,
    isFetching,
  } = useQuery(['ordersData', props_order_id], fetchOrderDetailsV2);

  const orderData = get(orderResponse, 'data.data', {});
  const order_id = get(orderData, 'order_id', '');
  const display_o_id = get(orderData, 'o_id', '');
  const store_id = get(orderData, 'store_id', '');

  useEffect(() => {
    if (store_id) {
      let active_store = active_stores.filter(
        (store: any) => store.store_id === store_id,
      )[0];
      setActiveStore(active_store);
      setRefundAllow(active_store.is_refund_allow && can_refund);
    }
  }, [store_id]);

  let { promo_version = 1, currency: storeCurrency, retailer } = active_store;

  const fetchPromoOrder = () => {
    return promotedOrderApi.query({
      format: 'json',
      store_id,
      order_id,
      v1: true,
      token: cookies.app_token,
    });
  };

  const { isLoading: promoLoading, data: promoResponse } = useQuery(
    ['promoData', order_id],
    fetchPromoOrder,
    { enabled: promo_version == 2 && order_id ? true : false },
  );

  const handleOrderStatus = (payload: any) => {
    return orderStatusApi.create(
      {
        v1: true,
        store_id,
        token: cookies.app_token,
      },
      JSON.stringify(payload),
    );
  };

  const handleCCOrderStatus = (payload: any) => {
    return changeCCOrderStatusApi.create(
      { v1: true, token: cookies.app_token },
      JSON.stringify(payload),
    );
  };

  const handleOrderRefundApi = (payload: any) => {
    try {
      if (promo_version === 1) {
        return refundOrder.create(
          { v1: true, token: cookies.app_token },
          JSON.stringify(payload),
        );
      } else if (promo_version === 2) {
        return refundOrderNew.create(
          { v1: true, token: cookies.app_token },
          JSON.stringify(payload),
        );
      }
    } catch (e) {
      console.log({ e });
      throw e;
    }
  };

  const { isLoading: refundLoading, mutate: triggerRefunds } = useMutation(
    handleOrderRefundApi,
    {
      onSuccess: (data: any) => {
        if (data?.data?.status == '400') {
          showToast({
            visible: true,
            message: data?.data?.message,
            severity: 'error',
          });
        } else {
          console.log('Refund Successfull');
          queryClient.invalidateQueries('ordersData');
          queryClient.invalidateQueries('promoData');
          if (type === 'click_collect') {
            queryClient.invalidateQueries('ccOrdersData');
          }
          showToast({
            visible: true,
            message: intl.formatMessage({ id: 'refundSuccess' }),
            severity: 'success',
          });
          setItemsToRefund([]);
        }
        setCancelCCOrder(false);
      },
      onError: (error: any) => {
        setCancelCCOrder(false);
        console.log({ error });
      },
    },
  );

  const { isLoading: orderStatusLoading, mutate: mutateOrderStatus } =
    useMutation(handleOrderStatus, {
      onSuccess: () => {
        queryClient.invalidateQueries('ordersData');
        queryClient.invalidateQueries('promoData');
      },
      onError: (error: any) => {
        console.log({ error });
      },
    });

  const { isLoading: changeCCOrdersLoading, mutate: changeCCOrderStatus } =
    useMutation(handleCCOrderStatus, {
      onSuccess: () => {
        queryClient.invalidateQueries('ordersData');
        queryClient.invalidateQueries('promoData');
        queryClient.invalidateQueries('ccOrdersData');
        showToast({
          visible: true,
          message: intl.formatMessage({ id: 'statusChanged' }),
          severity: 'success',
        });
      },

      onError: error => {
        showToast({
          visible: true,
          message: intl.formatMessage({ id: 'statusChangeError' }),
          severity: 'error',
        });
      },
    });

  const handleCancelAndRefund = (orderStatusPayload: any) => {
    setCancelLoading(true);
    handleCCOrderStatus(orderStatusPayload).then((ccOrderResponse: any) => {
      let payload = {};
      if (promo_version === 1) {
        payload = {
          order_id,
          store_id,
          return_type: 'FULL',
          return_items: [],
        };
      } else {
        payload = {
          order_id,
          store_id,
          mode: 'F',
          items: [],
        };
      }
      handleOrderRefundApi(payload).then((response: any) => {
        setCancelLoading(false);
        setCancelCCOrder(false);
        queryClient.invalidateQueries('ordersData');
        queryClient.invalidateQueries('promoData');
        queryClient.invalidateQueries('ccOrdersData');
        showToast({
          visible: true,
          message: intl.formatMessage({ id: 'cancelRefundSuccess' }),
          severity: 'success',
        });
      });
    });
  };

  const handleOrderSummary = (data: any) => {
    let items_available = get(data, 'basket.items_available', {});
    let items_returned = get(data, 'basket.items_returned', {});
    let is_price_exclusive_of_tax = get(
      data,
      'is_price_exclusive_of_tax',
      false,
    );
    let show_available =
      get(items_available, 'no_promos', []).length +
        get(items_available, 'promos', []).length >
      0;
    let show_returned =
      get(items_returned, 'no_promos', []).length +
        get(items_returned, 'promos', []).length >
      0;
    let total_order_amount = get(data, 'total_order_amount', null),
      total_refunded_amount = get(data, 'total_refunded_amount', null),
      last_refunded_amount = get(data, 'last_refunded_amount', null);
    let order_payment_data = {};
    total_order_amount || total_refunded_amount || last_refunded_amount
      ? (order_payment_data = {
          total_order_amount,
          total_refunded_amount,
          last_refunded_amount,
        })
      : (order_payment_data = {});

    return (
      <>
        {show_returned && (
          <>
            {itemsLayout(
              'refunded',
              [...items_returned.no_promos, ...items_returned.promos],
              false,
              order_payment_data,
              is_price_exclusive_of_tax,
            )}
          </>
        )}
        {show_available && (
          <>
            {itemsLayout(
              'promos',
              items_available.promos,
              is_refund_allow,
              {},
              is_price_exclusive_of_tax,
            )}
            {itemsLayout(
              'no_promos',
              items_available.no_promos,
              is_refund_allow,
              {},
              is_price_exclusive_of_tax,
            )}
          </>
        )}
      </>
    );
  };

  function generate(
    data: any,
    showRefunds: any,
    type: any,
    is_price_exclusive_of_tax: any,
  ) {
    return data.map((value: any, index: any) => {
      return renderCardContent({
        value,
        index,
        showRefunds,
        type,
        originalData: data,
        is_price_exclusive_of_tax,
      });
    });
  }

  const itemsLayout = (
    type: string,
    data: any,
    showRefunds: boolean = true,
    order_payment_data: any = {},
    is_price_exclusive_of_tax: boolean,
  ) => {
    let color = '',
      title = '',
      titleColor = 'black',
      total = 0;
    switch (type) {
      case 'promos':
        color = '#ffea26';
        title = intl.formatMessage({ id: 'itemsWithPromo' });
        break;
      case 'no_promos':
        color = '#a6c1ff';
        title = intl.formatMessage({ id: 'itemsWithoutPromo' });
        break;
      case 'refunded':
        color = '#ffd6d9';
        titleColor = '#ff3d71';
        let { total_refunded_amount } = order_payment_data;
        title = 'Refunded';

        total =
          total_refunded_amount !== null || total_refunded_amount !== undefined
            ? parseFloat(total_refunded_amount)
            : data.reduce((acc: number = 0, val: any): number => {
                try {
                  val.group_items.map((group_item: any) => {
                    let item = Array.isArray(group_item)
                      ? group_item[0]
                      : group_item;
                    let mrp = get(
                      item,
                      promo_version == 1
                        ? 'total_price_of_each_basket_item'
                        : 'mrp',
                      0,
                    );
                    let qty = get(item, 'qty', 1);
                    let accValue = acc.toFixed(2);
                    acc = parseFloat(accValue) + parseFloat(mrp) * qty;
                  });
                  let totalValue = acc.toFixed(2);
                  return parseFloat(totalValue);
                } catch (e) {
                  return 0;
                }
              }, 0);
        break;

      default:
        break;
    }

    if (data.length) {
      return (
        <>
          <Box
            className={classes.promotedLayout}
            style={{ border: `solid 1px ${color}` }}
          >
            <Box
              style={{
                display: 'flex',
                backgroundColor: color,
                padding: 8,
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography style={{ color: titleColor, textAlign: 'left' }}>
                {title}
              </Typography>
              {type === 'refunded' ? (
                <Typography>
                  {getCurrency(currency) + total.toFixed(2)}
                </Typography>
              ) : (
                <></>
              )}
            </Box>
            <List>
              {generate(data, showRefunds, type, is_price_exclusive_of_tax)}
            </List>
          </Box>
        </>
      );
    } else return <></>;
  };

  const renderCardContent = ({
    value: parent_items,
    index: parent_index,
    showRefunds,
    type,
    originalData,
    is_price_exclusive_of_tax,
  }: any) => {
    let group_title =
        get(parent_items, 'group_title', '') !== 'No Promos'
          ? get(parent_items, 'group_title', '')
          : '',
      group_items_qty_editable = get(
        parent_items,
        'group_items_qty_editable',
        true,
      );
    return (
      <ListItem key={parent_index} style={{ backgroundColor: 'white' }}>
        {parent_items.group_items.map((cardData: any, index: number) => {
          let item = Array.isArray(cardData) ? cardData[0] : cardData;
          let item_product_id = get(item, 'retailer_product_id', '');
          if (item_product_id === null) {
            item_product_id = get(item, 'product_identifier', '');
          }

          let item_name = get(item, 'item_name', ''),
            item_category = get(item, 'item_category', ''),
            img = get(item, 'img', ''),
            item_id = get(item, 'item_id', ''),
            mrpValue = get(
              item,
              promo_version == 1 ? 'total_price_of_each_basket_item' : 'mrp',
              '',
            ),
            mrp = `${getCurrency(currency)}${
              mrpValue ? parseFloat(mrpValue).toFixed(2) : ''
            }`,
            discountValue = get(
              item,
              promo_version == 1 ? 'discount_price' : 'discount',
              '',
            ),
            discount = `${
              discountValue && discountValue !== '0'
                ? getCurrency(currency) + parseFloat(discountValue).toFixed(2)
                : '--'
            }`,
            sub_totalValue = get(
              item,
              promo_version == 1 ? 'price' : 'sub_total',
              '',
            ),
            sub_total = `${getCurrency(currency)}${
              sub_totalValue ? parseFloat(sub_totalValue).toFixed(2) : ''
            }`,
            qty = get(item, promo_version == 1 ? 'item_quantity' : 'qty', ''),
            barcodeData = get(item, 'barcode', '')
              ? get(item, 'barcode', '')
              : get(item, 'product_identifier', ''),
            is_deposit_item = get(item, 'is_deposit_item', false),
            tax_amountValue = get(item, 'tax_amount', ''),
            tax_amount = `${getCurrency(currency)}${
              tax_amountValue ? parseFloat(tax_amountValue).toFixed(2) : '0'
            }`;

          const getDiscountValue = () => {
            if (type === 'no_promos') {
              return '--';
            } else if (type === 'refunded') {
              if (promo_version === 1) {
                return '--';
              } else return discount;
            } else return discount;
          };

          let orderValues = [
            {
              title: intl.formatMessage({ id: 'mrpPerUnit' }),
              value: mrp,
              show: true,
            },
            {
              title: intl.formatMessage({ id: 'qty' }),
              value: qty,
              show: true,
            },
            {
              title: intl.formatMessage({ id: 'discount' }),
              value: getDiscountValue(),
              show: true,
            },
            {
              title: intl.formatMessage({ id: 'tax' }),
              value: tax_amount,
              show: is_price_exclusive_of_tax,
            },
            {
              title: intl.formatMessage({ id: 'subtotal' }),
              value: sub_total,
              show: true,
            },
          ];

          return (
            <Box key={item_id} style={{ flex: 1 }}>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Box className={classes.imageAndtitleContainer}>
                  {img && (
                    <Box className={classes.itemImageContainer}>
                      <img src={img} className={classes.itemImage} />
                    </Box>
                  )}
                  <Box>
                    <Typography className={classes.itemName}>
                      {item_name}
                    </Typography>
                    <Typography className={classes.itemCategory}>
                      {`${item_category} | ${intl.formatMessage({
                        id: 'productID',
                      })} - ${item_product_id}`}
                    </Typography>
                  </Box>
                </Box>
                <Hidden xsDown>
                  <Box className={classes.buttonContainer}>
                    <Button
                      startIcon={<BarcodeShow />}
                      className={classes.barcodeShow}
                      variant="text"
                      size="small"
                      onClick={() =>
                        showBarcode({
                          visible: true,
                          type: 'barcode',
                          data: barcodeData,
                        })
                      }
                    >
                      {intl.formatMessage({ id: 'barcode' })}
                    </Button>
                    {orderValues.map((item: any, index: any) => {
                      return (
                        item.show && (
                          <Box
                            key={index}
                            className={classes.orderSummaryListContent}
                          >
                            <Typography className={classes.lightGreyTextList}>
                              {item.title}
                            </Typography>
                            <Typography className={classes.darkTextList}>
                              {item.value}
                            </Typography>
                          </Box>
                        )
                      );
                    })}
                  </Box>
                </Hidden>
              </Box>
              {group_title && (
                <Box className={classes.promoBox}>
                  <Typography className={classes.promoText}>
                    {group_title}
                  </Typography>
                </Box>
              )}
              <Hidden smUp>
                <Box className={classes.buttonContainer}>
                  <Button
                    startIcon={<BarcodeShow />}
                    className={classes.barcodeShow}
                    variant="text"
                    size="small"
                    onClick={() =>
                      showBarcode({
                        visible: true,
                        type: 'barcode',
                        data: barcodeData,
                      })
                    }
                  >
                    {intl.formatMessage({ id: 'barcode' })}
                  </Button>
                  <Box style={{ display: 'flex', flex: 1, flexWrap: 'wrap' }}>
                    {orderValues.map((item: any, index: any) => {
                      return (
                        item.show && (
                          <Box
                            key={index}
                            className={classes.orderSummaryListContent}
                          >
                            <Typography className={classes.lightGreyTextList}>
                              {item.title}
                            </Typography>
                            <Typography className={classes.darkTextList}>
                              {item.value}
                            </Typography>
                          </Box>
                        )
                      );
                    })}
                  </Box>
                </Box>
              </Hidden>
              {showRefunds && !is_deposit_item && (
                <RefundSelector
                  quantity={[...Array(qty + 1).keys()].slice(1)}
                  group_items_qty_editable={group_items_qty_editable}
                  item={item}
                  items_to_refund={items_to_refund}
                  setItemsToRefund={setItemsToRefund}
                  order_status={order_status}
                  cc_order_status={cc_order_status}
                />
              )}
              {parent_index < originalData.length - 1 ? (
                <Divider style={{ marginTop: 8 }} />
              ) : null}
            </Box>
          );
        })}
      </ListItem>
    );
  };

  const renderRefundButtons = (refundCount: number) => {
    return (
      <>
        <Button
          className={classes.buttonRefundLeft}
          variant="outlined"
          disabled={refundLoading}
          TouchRippleProps={{
            classes: { root: classes.buttonRefundLeftRipple },
          }}
          onClick={() => {
            if (refundCount) {
              showAlert(true);
            } else {
              console.log('Show Toast saying - error');
            }
          }}
        >
          {`${intl
            .formatMessage({ id: 'refund' })
            .toUpperCase()}(${refundCount})`}
        </Button>
        <Button
          className={classes.buttonRefundRight}
          variant="contained"
          disabled={refundLoading}
          TouchRippleProps={{
            classes: { root: classes.buttonRefundRightRipple },
          }}
          onClick={() => {
            setRefundAll(true);
            showAlert(true);
          }}
        >
          {intl.formatMessage({ id: 'refundAll' }).toUpperCase()}
        </Button>
      </>
    );
  };

  const renderFooterContent = () => {
    let items_available = get(UIData, 'basket.items_available', {});

    switch (order_status) {
      case 'completed':
        return (
          <Box
            className={classes.footer}
            borderTop={1}
            borderColor={theme.palette.color_light_grey.main}
          >
            <Button
              className={classes.buttonLeft}
              variant="outlined"
              TouchRippleProps={{ classes: { root: classes.buttonLeftRipple } }}
              onClick={() =>
                mutateOrderStatus({
                  order_id: order_id,
                  correct_order_status: false,
                })
              }
            >
              <FormattedMessage
                id="incorrect"
                defaultMessage="INCORRECT"
              ></FormattedMessage>
            </Button>
            <Button
              className={classes.buttonRight}
              variant="contained"
              TouchRippleProps={{
                classes: { root: classes.buttonRightRipple },
              }}
              onClick={() =>
                mutateOrderStatus({
                  order_id: order_id,
                  correct_order_status: true,
                })
              }
            >
              <FormattedMessage
                id="verifyOrder"
                defaultMessage="VERIFY ORDER"
              ></FormattedMessage>
            </Button>
          </Box>
        );
      case 'incorrect':
      case 'verification_failed':
      case 'verified':
        let refundCount;
        try {
          refundCount = items_to_refund.reduce(
            (acc: any, val: any) => (acc = acc + val.qty_refund),
            0,
          );
        } catch (e) {
          console.log('Error', e);
        }

        if (
          items_available?.promos?.length + items_available?.no_promos?.length >
            0 &&
          is_refund_allow
        ) {
          return (
            <Box
              className={classes.footer}
              borderTop={1}
              borderColor={theme.palette.color_light_grey.main}
            >
              {renderRefundButtons(refundCount)}
            </Box>
          );
        } else return <></>;

      case 'refunded': {
        let refundCount = items_to_refund.reduce(
          (acc: any, val: any) => (acc = acc + val.qty_refund),
          0,
        );
        if (
          items_available?.promos?.length +
            items_available?.no_promos?.length &&
          is_refund_allow
        ) {
          return (
            <Box
              className={classes.footer}
              borderTop={1}
              borderColor={theme.palette.color_light_grey.main}
            >
              {renderRefundButtons(refundCount)}
            </Box>
          );
        } else {
          return <></>;
        }
      }
      default:
        return <></>;
    }
  };

  const getButtonText = () => {
    switch (cc_order_status) {
      case 'created':
        return {
          status: intl.formatMessage({ id: 'acceptOrder' }),
          disabled: false,
          changeStatus: 'accepted',
        };
      case 'accepted':
        return {
          status: intl.formatMessage({ id: 'readyForCollection' }),
          disabled: false,
          changeStatus: 'ready_to_collect',
        };
      case 'ready_to_collect':
        return {
          status: intl.formatMessage({ id: 'collectedByCustomer' }),
          disabled: false,
          changeStatus: 'collected',
        };
      case 'collected':
        return {
          status: intl.formatMessage({ id: 'collected' }),
          disabled: true,
        };
      case 'collected_refunded':
        return {
          status: intl.formatMessage({ id: 'collectedRefund' }),
          disabled: true,
        };
      case 'rejected_customer':
        return {
          status: intl.formatMessage({ id: 'cancelledRefund' }),
          disabled: true,
        };
      case 'rejected_store':
        return {
          status: intl.formatMessage({ id: 'cancelledRefund' }),
          disabled: true,
        };
      case 'not_collected':
        return {
          status: intl.formatMessage({ id: 'cancelledRefund' }),
          disabled: true,
        };
      default:
        return { status: intl.formatMessage({ id: 'acceptOrder' }) };
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuVisible(false);
    setArrowRef(null);
  };

  const hideToast = () => {
    showToast({
      ...toast,
      visible: false,
    });
  };

  const ccRefundAvailableStatus = (value: any) =>
    [
      'collected',
      'rejected_store',
      'rejected_customer',
      'not_collected',
    ].includes(value);

  const renderCCFooterContent = () => {
    const disableHelp =
      order_status === 'refunded' &&
      (cc_order_status === 'collected' ||
        cc_order_status === 'rejected_store' ||
        cc_order_status === 'rejected_customer' ||
        cc_order_status === 'not_collected');

    const refundOrder =
      cc_order_status === 'collected' ||
      cc_order_status === 'rejected_store' ||
      cc_order_status === 'rejected_customer' ||
      cc_order_status === 'not_collected';

    return (
      <Box
        className={classes.footer}
        borderTop={1}
        borderColor={theme.palette.color_light_grey.main}
      >
        <Button
          className={classes.ccButtonLeft}
          variant="outlined"
          disabled={disableHelp}
          TouchRippleProps={{
            classes: { root: classes.buttonRefundLeftRipple },
          }}
          onClick={(e: any) => {
            if (is_refund_allow) {
              setAnchorEl(!menuVisible ? e.currentTarget : null);
              setMenuVisible(!menuVisible);
            } else {
              showToast({
                ...toast,
                visible: true,
                message: intl.formatMessage({ id: 'noPermissionRefunds' }),
                severity: 'error',
              });
            }
          }}
        >
          <HelpOutlineIcon />
        </Button>
        <Popper
          open={menuVisible}
          anchorEl={anchorEl}
          role={undefined}
          transition
          disablePortal
          modifiers={{
            arrow: {
              enabled: true,
              element: arrowRef,
            },
          }}
          style={{
            backgroundColor: 'white',
            zIndex: 5000,
            width: 200,
            marginLeft: 20,
            marginTop: 20,
            marginRight: 20,
          }}
        >
          {({ TransitionProps, placement }: any) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: 'center top' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <Paper>
                    <MenuList autoFocusItem={menuVisible} id="menu-list-grow">
                      <MenuItem
                        style={{ width: 200 }}
                        onClick={() => {
                          let selected_items_to_refund_count =
                            items_to_refund.reduce(
                              (acc: any, val: any) =>
                                (acc = acc + val.qty_refund),
                              0,
                            );
                          if (selected_items_to_refund_count > 0) {
                            setMenuVisible(false);
                            setAnchorEl(null);
                            setArrowRef(null);
                            showCCAlert(true);
                          } else {
                            setMenuVisible(false);
                            setAnchorEl(null);
                            setArrowRef(null);
                            showCCAlert(false);
                            showToast({
                              ...toast,
                              visible: true,
                              message: intl.formatMessage({
                                id: 'selectItemsAlert',
                              }),
                              severity: 'error',
                            });
                          }
                        }}
                      >
                        {intl.formatMessage({ id: 'refundSelected' })}
                      </MenuItem>
                      <MenuItem
                        style={{ width: 200 }}
                        onClick={() => {
                          setCancelCCOrder(true);
                          setMenuVisible(false);
                          setAnchorEl(null);
                          setArrowRef(null);
                          showCCAlert(true);
                        }}
                      >
                        {refundOrder
                          ? intl.formatMessage({ id: 'refundOrder' })
                          : intl.formatMessage({ id: 'cancelOrder' })}
                      </MenuItem>
                    </MenuList>
                    <span className={classes.arrow} ref={setArrowRef} />
                  </Paper>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <Button
          className={classes.ccButtonRight}
          variant="contained"
          disabled={getButtonText().disabled}
          TouchRippleProps={{
            classes: { root: classes.buttonRefundRightRipple },
          }}
          onClick={() =>
            changeCCOrderStatus({
              order_id: order_id,
              status: getButtonText().changeStatus,
            })
          }
        >
          {getButtonText().status?.toUpperCase()}
        </Button>
      </Box>
    );
  };

  const promoData = get(promoResponse, 'data.data', {});
  let date = get(orderData, 'date', ''),
    order_status = get(orderData, 'order_status', ''),
    cc_order_status = get(orderData, 'cc_order_status', ''),
    purchase_type = get(orderData, 'purchase_type', ''),
    discounted_price = get(promoData, 'total_order_amount', ''),
    transaction_id = get(orderData, 'transaction_id', '--'),
    currency = get(orderData, 'currency', '')
      ? get(orderData, 'currency', '')
      : storeCurrency,
    c_id = get(orderData, 'customer.c_id', ''),
    first_name = get(orderData, 'customer.first_name', ''),
    last_name = get(orderData, 'customer.last_name', ''),
    email = get(orderData, 'customer.is_guest_user', false)
      ? get(orderData, 'customer.guest_user_email', '')
      : get(orderData, 'customer.email', ''),
    payment_method = get(orderData, 'payment_details.payment_method', ''),
    qr_identifier = get(orderData, 'qr_identifier', '');

  let UIData = constructUIData(promo_version, orderData, promoData);

  let partialRefund =
    get(orderData, 'non_refunded_items', []).length &&
    get(orderData, 'refunded_items', []).length > 0
      ? true
      : false;

  let isThisClickAndCollectOrder = false;
  if (type != null) {
    if (type === 'click_collect') {
      isThisClickAndCollectOrder = true;
    }
  } else {
    if (purchase_type === 'click_and_collect') {
      isThisClickAndCollectOrder = true;
    }
  }

  let orderSummary = [
    {
      title: intl.formatMessage({ id: 'total' }),
      value: discounted_price
        ? getCurrency(currency) + parseFloat(discounted_price).toFixed(2)
        : '',
    },
    {
      title: intl.formatMessage({ id: 'items' }),
      value: getOrderCount(orderData),
    },
    {
      title: intl.formatMessage({ id: 'basketQr' }),
      value: qr_identifier,
      type: 'qr',
      visible: false,
    },
    {
      title: intl.formatMessage({ id: 'type' }),
      value: intl.formatMessage({ id: 'click&Collect' }),
    },
    {
      title: intl.formatMessage({ id: 'customerId' }),
      value: c_id,
    },
    {
      title: intl.formatMessage({ id: 'customer' }),
      value: first_name + ' ' + last_name,
    },
    {
      title: intl.formatMessage({ id: 'email' }),
      value: email,
    },
    {
      title: intl.formatMessage({ id: 'transactionId' }),
      value: transaction_id,
    },
    {
      title: intl.formatMessage({ id: 'paymentMethod' }),
      value: startCase(payment_method),
    },
    {
      title: intl.formatMessage({ id: 'storeName' }),
      value: retailer,
    },
  ];

  let available_items_count = React.useMemo(
    () =>
      get(orderData, 'non_refunded_items', []).reduce(
        (acc: any, val: any) => (acc = acc + val.item_quantity),
        0,
      ),
    [orderData],
  );

  const confirmRefunds = () => {
    let available_items_count = get(orderData, 'non_refunded_items', []).reduce(
      (acc: any, val: any) => (acc = acc + val.item_quantity),
      0,
    );
    let selected_items_to_refund_count = items_to_refund.reduce(
      (acc: any, val: any) => (acc = acc + val.qty_refund),
      0,
    );

    if (promo_version === 1) {
      if (
        available_items_count === selected_items_to_refund_count ||
        shouldRefundAll
      ) {
        let payload = {
          order_id,
          store_id,
          return_type: 'FULL',
          return_items: items_to_refund,
        };
        triggerRefunds(payload);

        setRefundAll(false);
      } else {
        let payload = {
          order_id,
          store_id,
          return_type: 'PARTIAL',
          return_items: items_to_refund,
        };
        triggerRefunds(payload);
      }
    } else if (promo_version === 2) {
      if (
        available_items_count === selected_items_to_refund_count ||
        shouldRefundAll
      ) {
        //refund all
        let payload = {
          items: [],
          mode: 'F',
          order_id,
          store_id,
        };
        triggerRefunds(payload);
        setRefundAll(false);
      } else {
        //partial refund
        let payload = {
          items: items_to_refund,
          mode: 'P',
          order_id,
          store_id,
        };
        triggerRefunds(payload);
      }
    }
    showAlert(false);
  };

  const initiatePartialOrFullRefund = (shouldCancelOrder: any = false) => {
    let selected_items_to_refund_count = items_to_refund.reduce(
      (acc: any, val: any) => (acc = acc + val.qty_refund),
      0,
    );
    const areAllItemsSelected =
      available_items_count === selected_items_to_refund_count;
    console.log({
      areAllItemsSelected,
      selected_items_to_refund_count,
      items_to_refund,
      shouldCancelOrder,
    });

    if (areAllItemsSelected) {
      if (shouldCancelOrder) {
        handleCancelAndRefund({
          order_id: order_id,
          status:
            cc_order_status === 'ready_to_collect'
              ? 'not_collected'
              : 'rejected_store',
        });
      } else {
        let payload = {};
        if (promo_version === 1) {
          payload = {
            order_id,
            store_id,
            return_type: 'FULL',
            return_items: [],
          };
        } else {
          payload = {
            order_id,
            store_id,
            mode: 'F',
            items: [],
          };
        }
        triggerRefunds(payload);
      }
    } else {
      let payload = {};
      if (promo_version === 1) {
        payload = {
          order_id,
          store_id,
          return_type: 'PARTIAL',
          return_items: items_to_refund,
        };
      } else {
        payload = {
          items: items_to_refund,
          mode: 'P',
          order_id,
          store_id,
        };
      }
      triggerRefunds(payload);
    }
  };

  const confirmCCRefunds = () => {
    const isRefundOrder = ccRefundAvailableStatus(cc_order_status);
    if (cancelCCOrder) {
      handleCancelAndRefund({
        order_id: order_id,
        status:
          cc_order_status === 'ready_to_collect'
            ? 'not_collected'
            : 'rejected_store',
      });
    } else if (!isRefundOrder) {
      initiatePartialOrFullRefund(true);
    } else {
      initiatePartialOrFullRefund(false);
    }
    showCCAlert(false);
  };

  const alertModal = () => {
    return (
      <Dialog open={alertVisible} onClose={() => showAlert(false)}>
        <DialogTitle>{'Refunds'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {intl.formatMessage({ id: 'confirmRefund' })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setRefundAll(false);
              showAlert(false);
            }}
            color="primary"
          >
            {intl.formatMessage({ id: 'no' }).toUpperCase()}
          </Button>
          <Button onClick={confirmRefunds} color="primary" autoFocus>
            {intl.formatMessage({ id: 'yes' }).toUpperCase()}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const ccAlertModal = () => {
    const refundOrder =
      cc_order_status === 'collected' ||
      cc_order_status === 'rejected_store' ||
      cc_order_status === 'rejected_customer' ||
      cc_order_status === 'not_collected';
    return (
      <Dialog open={ccAlertVisible} onClose={() => showCCAlert(false)}>
        <DialogTitle>{'Refunds'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {refundOrder
              ? intl.formatMessage({ id: 'confirmRefund' })
              : intl.formatMessage({ id: 'cancelAndRefund' })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: '#ff116e', color: '#FFFFFF' }}
            onClick={() => {
              showCCAlert(false);
            }}
          >
            {intl.formatMessage({ id: 'no' }).toUpperCase()}
          </Button>
          <Button
            onClick={confirmCCRefunds}
            style={{ backgroundColor: '#00e58f', color: '#FFFFFF' }}
            autoFocus
          >
            {intl.formatMessage({ id: 'yes' }).toUpperCase()}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const barcodeContent = () => {
    try {
      let barcodeData = barcode.data;
      return <></>;
    } catch (e) {
      console.log({ e });

      return (
        <Typography>{intl.formatMessage({ id: 'qrNotFound' })}</Typography>
      );
    }
  };

  const barcodeModal = () => {
    return (
      <Dialog open={barcode?.visible} onClose={() => showBarcode(barcodeInit)}>
        <DialogTitle>{intl.formatMessage({ id: 'tapDismiss' })}</DialogTitle>
        <DialogContent>{barcodeContent()}</DialogContent>
      </Dialog>
    );
  };

  const { icon, status, color, variant } = isThisClickAndCollectOrder
    ? order_status === 'refunded' && cc_order_status === 'collected'
      ? orderStatus('collected_refunded', partialRefund)
      : orderStatus(cc_order_status, partialRefund)
    : orderStatus(order_status, partialRefund);

  const classes = useStyles({
    b1bg: 'error',
    b2bg: 'success',
    color,
    variant,
  });

  return (
    <Paper className={classes.root}>
      <Box className={classes.header}>
        <NavigationTopBar
          title={`${intl.formatMessage({ id: 'order' })} ${display_o_id}`}
          subtitle={
            date
              ? `${moment(date).format('DD/MM/YY HH:mm')} UTC(${moment(date)
                  .utc()
                  .format('HH:mm')})`
              : ''
          }
          refresh={true}
          onRefreshClick={() =>
            queryClient.invalidateQueries(['ordersData', 'order_id'])
          }
        />
      </Box>
      <Hidden only={['xs']} implementation="css">
        {(refundLoading ||
          orderStatusLoading ||
          changeCCOrdersLoading ||
          cancelLoading) && <LinearProgress color="primary" />}
      </Hidden>
      <Box className={classes.body}>
        {isLoading || promoLoading ? (
          <Box className={classes.loader}>
            <CircularProgress color="primary" size={30} />
          </Box>
        ) : error || Object.keys(orderData).length > 0 ? (
          <Box className={classes.bodyContentContainer}>
            <Box className={classes.orderSummary}>
              <Button
                className={classes.statusButton}
                variant="outlined"
                endIcon={icon}
              >
                {status}
              </Button>
              {orderSummary.map((item: any, index: number) => {
                let { title, value, type, visible } = item;
                if (isThisClickAndCollectOrder && type != 'qr') {
                  return (
                    <Box className={classes.orderSummaryContent} key={index}>
                      <Typography>{title}</Typography>
                      <Typography>{value}</Typography>
                    </Box>
                  );
                } else {
                  switch (type) {
                    case 'qr': {
                      if (value) {
                        return (
                          <Box
                            key={index}
                            className={classes.orderSummaryContent}
                          >
                            <Typography className={classes.lightGreyText}>
                              {title}
                            </Typography>
                            <Button
                              startIcon={<BarcodeShow />}
                              className={classes.barcodeShow}
                              variant="text"
                              size="small"
                              style={{ alignSelf: 'flex-start', height: 18 }}
                              onClick={() =>
                                showBarcode({
                                  visible: true,
                                  type: 'qr',
                                  data: value,
                                })
                              }
                            >
                              {intl.formatMessage({ id: 'barcode' })}
                            </Button>
                          </Box>
                        );
                      } else return <></>;
                    }
                    default: {
                      if (title === 'Type') return null;
                      return (
                        <Box
                          key={index}
                          className={classes.orderSummaryContent}
                        >
                          <Typography className={classes.lightGreyText}>
                            {title}
                          </Typography>
                          <Typography className={classes.darkText}>
                            {value}
                          </Typography>
                        </Box>
                      );
                    }
                  }
                }
              })}
            </Box>
            <Typography variant="h6" style={{ textAlign: 'left' }}>
              Order Response
            </Typography>
            <Editor mode="json" value={orderResponse} height={500} />
            <Typography variant="h6" style={{ textAlign: 'left' }}>
              Promo Response
            </Typography>
            <Editor mode="json" value={promoResponse} height={500} />
            <Box className={classes.outerBody}>
              <Box className={classes.innerBody}>
                <Typography className={classes.orderSummaryText}>
                  <FormattedMessage
                    id="orderSummary"
                    defaultMessage="Order Summary"
                  />
                </Typography>
                <Box className={classes.orderSummaryUI}>
                  {handleOrderSummary(UIData)}
                </Box>
              </Box>
            </Box>
            {isThisClickAndCollectOrder
              ? renderCCFooterContent()
              : renderFooterContent()}
          </Box>
        ) : (
          <Box className={classes.loader}>
            <Typography>
              <h6>
                <FormattedMessage id="noOrderDetails" />
              </h6>
            </Typography>
          </Box>
        )}
        {alertModal()}
        {ccAlertModal()}
        {barcodeModal()}
      </Box>
      <Hidden only={['sm', 'md', 'lg', 'xl']} implementation="css">
        {(refundLoading ||
          orderStatusLoading ||
          changeCCOrdersLoading ||
          cancelLoading) && (
          <Box className={classes.mobileLoaderContainer}>
            <Box className={classes.mobileLoader}>
              <CircularProgress
                className={classes.circle}
                size={25}
                thickness={5}
              ></CircularProgress>
              <Typography>Loading...</Typography>
            </Box>
          </Box>
        )}
      </Hidden>
      <Snackbar
        open={toast?.visible}
        autoHideDuration={3000}
        onClose={hideToast}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Alert onClose={hideToast} severity={toast?.severity}>
          {toast?.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default memo(OrderDetails);
